html, body {
  height: 100%;
}

#root {
  height: 100%;
}

.home {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
