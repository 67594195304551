.onboarding {
  padding-top: 8.5rem;
}

.header {
  padding: 1rem 0;
  border-bottom: $border-width solid $gray-400;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: $body-bg;

  .header-content {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .flex-wrap;

    h1 {
      font-weight: 700;
      display: flex;
      align-items: center;

      img {
        height: 50px;
      }
    }
  }
}
