label {
  font-weight: bold;
  padding: 5px;

  &.has-errors {
    @extend .text-danger;
    //outline: 1px solid $danger;
  }
}

.onboarding-form {
  padding: 0 0 2rem 0;
}

.form-group {
  &.has-errors {
    //border: 1px solid $danger;
  }

  .btn-add-field {
    width: 100%;
  }

  &.row {
    align-items: baseline;
  }
}

.nested-row {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .flex-wrap;

  label {
    @extend .small;
    font-weight: normal;
  }

  position: relative;
  //padding: 1rem;
  //border: $border-width solid $gray-400;
  //border-radius: $border-radius;
  margin-bottom: .5rem;

  &-field {
    flex: 0 0 48%;
    //padding: 0 1rem;
    margin-bottom: 1rem;
  }
}

.child-row {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .flex-wrap;

  label {
    @extend .small;
    font-weight: normal;
  }

  position: relative;
  padding: 1rem;
  border: $border-width solid $gray-400;
  border-radius: $border-radius;
  margin-bottom: .5rem;

  &-field {
    flex: 1 0 30%;
    padding: 0 1rem;
    margin-bottom: 1rem;
  }

  &-remove {
    @include transition(all, .2s);
    background: #fff;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: -12px;
    color: $red;
    font-size: 24px;
    margin: -12px 0 0 0;

    &:hover {
      color: lighten($red, 20%);
    }
  }
}
