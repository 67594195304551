.footer {
  padding: 1rem 0;
  border-top: $border-width solid $gray-400;
}

.tac-container {
  @extend .d-flex;
  padding: 3rem 5rem;
  position: fixed;
  top: 0;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;

  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
}
