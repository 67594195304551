/*! `Custom` Bootstrap 4 theme */

@import url(https://fonts.googleapis.com/css?family=Nunito:200,300,400,700);

$headings-font-family: Nunito;
$headings-font-weight: 700;

//$enable-grid-classes:false;
$primary: #27a2fc;
$secondary: #a0b7bc;
$success: #37bd74;
$danger: #e8286b;
$info: #7ebcfa;
$warning: #ff9b37;
$light: #eef0f2;
$dark: #3c4055;
$enable-gradients: false;
$spacer: 1rem;
$border-width: 3px;
$btn-border-radius: 0;

$border-radius: 0 !default;
$border-radius-lg: 0 !default;
$border-radius-sm: 0 !default;

//@import "variables";

@import '~bootstrap/scss/bootstrap';
//@import "bootswatch";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

@import "global";
@import "header";
@import "footer";
@import "form";
